<template>
  <div class="login_container">
    <div class="bg_shadow"></div>
    <div class="bg_image">
      <img
        :src="loginbg"
        alt=""
      />
    </div>
    <a-row class="form_container">
      <a-col>
        <div class="login_logo">
          <h3>{{ loginName }}</h3>
          <h4>{{loginEnName}}</h4>
        </div>
        <a-form
          id="formLogin"
          class="user-layout-login"
          ref="formLogin"
          :form="form"
          @submit="handleSubmit"
        >
          <!-- <a-tabs
        :activeKey="customActiveKey"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
        @change="handleTabClick"
      >
        <a-tab-pane key="tab1" tab="账号密码登录">
          <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" message="账户或密码错误（admin/ant.design )" /> -->
          <a-form-item>
            <a-input
              size="large"
              type="text"
              placeholder="请输入登录账号"
              v-decorator="[
                'username',
                {
                  rules: [
                    { required: true, message: '请输入帐户名或邮箱地址' },
                    { validator: handleUsernameOrEmail },
                  ],
                  validateTrigger: 'change',
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="user"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-form-item>

          <a-form-item>
            <a-input-password
              size="large"
              placeholder="请输入登录密码"
              v-decorator="[
                'password',
                {
                  rules: [{ required: true, message: '请输入密码' }],
                  validateTrigger: 'blur',
                },
              ]"
            >
              <a-icon
                slot="prefix"
                type="lock"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-row :gutter="16">
              <a-col class="gutter-row" :span="16">
                <a-form-item>
                  <a-input
                    size="large"
                    type="text"
                    placeholder="图形验证码"
                    v-decorator="[
                      'imgCaptchaCode',
                      {
                        rules: [{ required: true, message: '请输入验证码' }],
                        validateTrigger: 'blur',
                      },
                    ]"
                  >
                    <a-icon
                      slot="prefix"
                      type="mail"
                      :style="{ color: 'rgba(0,0,0,.25)' }"
                    />
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col class="gutter-row" :span="8">
                <img
                  class="img_captcha"
                  :src="baseUrl + '/admin/login/getCaptcha?uuid=' + uuid"
                  @click="getCaptcha"
                />
              </a-col>
            </a-row>
          </a-form-item>
          <!-- </a-tab-pane> -->
          <!-- <a-tab-pane key="tab2" tab="手机号登录">
          <a-form-item>
            <a-input size="large" type="text" placeholder="手机号" v-decorator="['mobile', {rules: [{ required: true, pattern: /^1[34578]\d{9}$/, message: '请输入正确的手机号' }], validateTrigger: 'change'}]">
              <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
            </a-input>
          </a-form-item>

          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item>
                <a-input size="large" type="text" placeholder="验证码" v-decorator="['captcha', {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}]">
                  <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <a-button
                class="getCaptcha"
                tabindex="-1"
                :disabled="state.smsSendBtn"
                @click.stop.prevent="getCaptcha"
                v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
              ></a-button>
            </a-col>
          </a-row> -->
          <!-- </a-tab-pane>
      </a-tabs> -->

          <!-- <a-form-item>
            <router-link :to="{ name: 'recover', params: { user: 'aaa' } }" class="forge-password" style="float: right;"
              >忘记密码</router-link
            >
          </a-form-item> -->

          <a-form-item class="login_btn">
            <a-button
              size="large"
              :loading="$store.state.requestLoading"
              type="primary"
              htmlType="submit"
              class="login-button"
              >登录</a-button
            >
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as Api from "./api";
import * as commonApi from "@/commonApi";

export default {
  data() {
    return {
      loginBtn: false,
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
      form: this.$form.createForm(this),
      baseUrl: process.env.VUE_APP_API,
      uuid: new Date().getTime(),
      customActiveKey: "tab1",
      loginLoading: false,
      loginEnName: "",
      loginName: "",
      loginbg:window.localStorage.getItem('loginbg')
    };
  },
  mounted() {
    let platformCode = localStorage.getItem("platformCode");
    this.loginName =
      platformCode == "saas"
        ? "安全考核系统"
        : platformCode == "company"
        ? "甘肃天安安全考核系统"
        : platformCode == "labor_team"
        ? "甘肃天安安全考核系统"
        : "测试";
    this.loginEnName =
      platformCode == "saas"
        ? "总平台"
        : platformCode == "company"
        ? "劳务公司平台"
        : platformCode == "labor_team"
        ? "劳务队平台"
        : "TEST";
  },
  methods: {
    async getCaptcha() {
      this.uuid = new Date().getTime();
    },
    handleSubmit(e) {
      const self = this;

      e.preventDefault();
      const {
        form: { validateFields },
        state,
        customActiveKey,
        Login,
      } = this;

      state.loginBtn = true;

      const validateFieldsKey =
        customActiveKey === "tab1"
          ? ["username", "password", "imgCaptchaCode"]
          : ["mobile", "captcha"];

      // 表单验证
      validateFields(
        validateFieldsKey,
        { force: true },
        async (err, values) => {
          if (!err) {
            const loginParams = { ...values };
            this.loginLoading = true;
            const res = await Api.Login({
              login_account: loginParams.username,
              login_pwd: loginParams.password,
              verification_code: loginParams.imgCaptchaCode,
              uuid: this.uuid,
            });
            // 登录失败
            if (res.code !== "0") {
              this.requestFailed(res.message);
              state.loginBtn = false;
              this.getCaptcha();
              return;
            }
            // 登录成功
            localStorage.setItem("login_code", res.login_code);
            window.login_code = res.login_code;
            this.loginSuccess();
            this.loginLoading = false;
          } else {
            setTimeout(() => {
              state.loginBtn = false;
            }, 600);
          }
        }
      );
    },
    async getMenuList() {
      return new Promise(async (resolve, rej) => {
        const res = await commonApi.GetMenu();
        if (res.code == '0') {
          window.localStorage.setItem('admin', JSON.stringify(res['admin']))
          window.localStorage.setItem('platformId', res.admin.platform_id)
          window.localStorage.setItem('systemObj', JSON.stringify(res.admin.platform_account_obj))
          this.$store.commit('SET_DYNAMIC_MENU', res.menuList);
          //上线前一定要注释掉
          // console.log(res.admin.name);
          // if(res.admin.name == '劳务队管理员'){
          //   window.localStorage.setItem('platformCode', 'labor_team')
          // }else{
          //   window.localStorage.setItem('platformCode', 'company')
          // }
        }
        resolve();
      });
    },
    async loginSuccess() {
      await this.getMenuList();
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$router.push({ path: "/" });
        this.$notification.success({
          message: "欢迎",
          description: `欢迎回来`,
        });
        // window.location.reload();
      }, 1000);
      this.isLoginError = false;
    },

    requestFailed(err) {
      this.isLoginError = true;
      this.$notification["error"]({
        message: "错误",
        description: err || "请求出现错误，请稍后再试",
        duration: 4,
      });
    },
    handleUsernameOrEmail(rule, value, callback) {
      const { state } = this;
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (regex.test(value)) {
        state.loginType = 0;
      } else {
        state.loginType = 1;
      }
      callback();
    },
  },
};
</script>

<style lang="less">
.login_container {
  background: #f0f2f5;
  height: 100vh;
  position: relative;
  .bg_image,
  .bg_shadow {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    z-index: 1;
    img {
      width: 100%;
      opacity: 0.9;
    }
  }
  .bg_shadow {
    // background: #5d22004a;
    z-index: 2;
  }
  .form_container {
    position: absolute;
    z-index: 3;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -230px;
    background: #ffffff8c;
    padding: 20px;
    border-radius: 3px;
  }
  .login_logo {
    margin-bottom: 20px;
    h3 {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }
  .img_captcha {
    border: 1px solid rgb(195, 195, 195);
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    border-radius: 3px;
    margin-top: -3px;
  }
  .login_btn {
    .ant-btn-lg {
      width: 100%;
    }
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
}
</style>
